import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'

import MetaHeader from '../components/MetaHeader'
import PageWrapper from '../components/PageWrapper'
import ArticleHeader from '../components/common/ArticleHeader'

const BlogPost = ({ data }) => {
  const { title, body } = data.contentfulResource

  return (
    <Layout>
      <MetaHeader title={title} />
      <PageWrapper>
        <ArticleHeader title={title} />
        <section>
          <div
            dangerouslySetInnerHTML={{
              __html: `${body.childContentfulRichText.html}`,
            }}
          />
        </section>
      </PageWrapper>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulResource(slug: { eq: $slug }) {
      title
      slug
      body {
        childContentfulRichText {
          html
        }
      }
    }
  }
`
